// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "jquery"
import "../commons/plugins/jquery.cookie.min.js"

// tag-it
import "../commons/plugins/tag-it/js/tag-it.min.js"
import "../commons/plugins/tag-it/css/jquery.tagit.css"

// raty
//import "../commons/plugins/jquery-raty/jquery.raty.js"
import "../commons/plugins/jquery-raty/jquery.raty.css"

// lity
import "../commons/plugins/lity/lity.js"
import "../commons/plugins/lity/lity.css"

import "../site/js/commons.js"

import "../admin/css/vendor.min.css"

Rails.start();
ActiveStorage.start();
