const SITE_TITLE = '森のささやき探検隊';
const SITE_TITLE_PARAGRAPH = '│';

const DEFAULT_LATITUDE = 35.68126232447217;
const DEFAULT_LONGITUDE = 139.76713016222465;
const DEFAULT_ZOOM = 16;

let default_options = {
    zoom: DEFAULT_ZOOM,
    mapTypeControl: false,
    fullscreenControl: true,
    streetViewControl: false,
    zoomControl: true,
};

getGoogleMapOptions = function(latitude, longitude) {
    let options = default_options;
    if (latitude) {
        options.center = new google.maps.LatLng(latitude, longitude);
    }
    return options;
};

geolocation = function(callbackFunc) {

    const json = Cookies.get('location');
    if (json) {
        console.log('load location: ' + json);
        const location = JSON.parse(json);
        callbackFunc(location.latitude, location.longitude);
        return;
    }

    if ('geolocation' in navigator) {
        const opt = {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0,
        };
        navigator.geolocation.getCurrentPosition(function(position) {
            // store to cookies
            let date = new Date();
            date.setTime( date.getTime() + (1 * 60 * 60 * 1000));
            const location = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            };
            console.log('store location: ' + JSON.stringify(location));
            Cookies.set('location', JSON.stringify(location), { expires: date, path: '' });
            callbackFunc(position.coords.latitude, position.coords.longitude);
        }, function(error) {
            switch(error.code){
                case 1:
                    console.log("位置情報の利用が許可されていません");
                    break;
                case 2:
                    console.log("デバイスの位置が判定できません");
                    break;
                case 3:
                    console.log("タイムアウトしました");
                    break;
                default :
                    console.log(error.message);
            }
            callbackFunc(DEFAULT_LATITUDE, DEFAULT_LONGITUDE);
        }, opt);
    } else {
        console.log("ブラウザが位置情報取得に対応していません");
        callbackFunc(DEFAULT_LATITUDE, DEFAULT_LONGITUDE);
    }
};

setGoogleMapZoomValue = function(zoom) {
    default_options.zoom = zoom;
};

setRaty = function(element, score, click_function) {
    const read_only = (click_function == null);
    $(element).raty({
        number: 5,
        score: score,
        half: read_only,
        animate: false,
        starHalf: '/images/star_half.svg',
        starOff: !read_only ? '/images/star_gray_full.svg' : '/images/star_emp.svg',
        starOn: !read_only ? '/images/star_green_full.svg' : '/images/star_full.svg',
        click: function (score) {
            if (click_function) click_function(score);
        },
        readOnly: read_only,
    });
};

setSiteTitle = function(sub_title) {
    document.title = sub_title + SITE_TITLE_PARAGRAPH + SITE_TITLE;
};

$(function () {
    $('.raty').each(function(index, element) {
        setRaty(element, $(element).data('score'));
    });

    $(".tagIt").tagit({
        readOnly: true
    });
});
